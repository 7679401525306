<template>

    <div class="module" v-if="module">

        <div class="row">
            <div class="module-display gr-24">
<!--                <div class="module-display-video gr-11 gr-24@tablet">-->

<!--                    <router-link :to="{ name: 'module', params: { 'moduleSlug': module.slug } }" style="backgroundColor: black">-->
<!--                        <img :src="cmsUrl + '/public' + module.image.url" :alt="module.title" v-if="module.image">-->
<!--                    </router-link>-->

<!--                </div>-->

<!--                <div class="module-display-container gr-13 gr-24@tablet">-->
                <div class="module-display-container gr-18 gr-24@tablet">

                    <div class="row">
                        <div class="gr-24 module-display-container-data">
                            <h4>Kapitel: {{ module.title }}</h4>
                            <p v-if="module.description">{{ module.description }}</p>
                        </div>
                    </div>

                    <div class="row">
                        <div class="gr-24 module-display-container-button">
                            <base-button @click="lastPosition? $emit('moveToLastPosition') : $emit('moveToModule', module.slug)" class="button">{{ lastPosition? 'Fortsetzen' : 'Öffnen' }}</base-button>
                        </div>
                    </div>

                </div>

                <!--div class="gr-2">
                    {{ user.completed && user.completed[training.slug] && user.completed[training.slug][module.slug] && user.completed[training.slug][module.slug]['done'] === true? 'abg.' : 'offen' }}
                </div-->
            </div>
        </div>

    </div>

</template>

<script>
    export default {
        props: ['module', 'lastPosition'],
        emits: ['moveToModule', 'moveToLastPosition'],
        data() {
          return {
            cmsUrl: process.env.VUE_APP_STRAPI_API_URL
          }
        }
    }
</script>

<style scoped>
</style>
